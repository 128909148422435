@import 'header';
@import 'alert';
@import 'accordions';
@import 'breadcrumbs';
@import 'card';
@import 'dropdowns';
@import 'buttons';
@import 'widgets';
@import 'sidebar';
@import 'testimonials';
@import 'nav';
@import 'list-group';
@import 'navbar';
@import 'boxes';
@import 'tables';
@import 'figure';
@import 'product';
@import 'carousel';
@import 'modal';
@import 'swiper';
@import 'partners';
@import 'pagination';
@import 'custom-forms';
@import 'blog';
@import 'order';
@import 'code';