@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?i7gibo');
  src:  url('../fonts/icomoon.eot?i7gibo#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?i7gibo') format('truetype'),
    url('../fonts/icomoon.woff?i7gibo') format('woff'),
    url('../fonts/icomoon.svg?i7gibo#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-Arrow---Up:before {
  content: "\e900";
}
.icon-Arrow---Down-Left:before {
  content: "\e901";
}
.icon-Arrow---Down-Right:before {
  content: "\e902";
}
.icon-Arrow---Down:before {
  content: "\e903";
}
.icon-Arrow---Left:before {
  content: "\e904";
}
.icon-Arrow---Right:before {
  content: "\e905";
}
.icon-Arrow---Up-Left:before {
  content: "\e906";
}
.icon-Arrow---Up-Right:before {
  content: "\e907";
}
.icon-Chevon---Left:before {
  content: "\e908";
}
.icon-Chevron---Down:before {
  content: "\e909";
}
.icon-Chevron---Right:before {
  content: "\e90a";
}
.icon-Chevron---Up:before {
  content: "\e90b";
}
.icon-Stethoscope:before {
  content: "\e90c";
}
.icon-Bandage:before {
  content: "\e90d";
}
.icon-First-Aid:before {
  content: "\e90e";
}
.icon-Flask:before {
  content: "\e90f";
}
.icon-Medical-Cross:before {
  content: "\e910";
}
.icon-Microscope:before {
  content: "\e911";
}
.icon-Pharmacy-Bowl:before {
  content: "\e912";
}
.icon-Pill:before {
  content: "\e913";
}
.icon-Syringe:before {
  content: "\e914";
}
.icon-Wheelchair:before {
  content: "\e915";
}
.icon-Ship:before {
  content: "\e916";
}
.icon-Airplane:before {
  content: "\e917";
}
.icon-Bicycle:before {
  content: "\e918";
}
.icon-Bus:before {
  content: "\e919";
}
.icon-Car:before {
  content: "\e91a";
}
.icon-Direction:before {
  content: "\e91b";
}
.icon-Gas:before {
  content: "\e91c";
}
.icon-Landing:before {
  content: "\e91d";
}
.icon-Space-Shuttle:before {
  content: "\e91e";
}
.icon-Take-Off:before {
  content: "\e91f";
}
.icon-Traffic-Cone:before {
  content: "\e920";
}
.icon-Train:before {
  content: "\e921";
}
.icon-Truck:before {
  content: "\e922";
}
.icon-Wind:before {
  content: "\e923";
}
.icon-Cloud:before {
  content: "\e924";
}
.icon-Cloudy:before {
  content: "\e925";
}
.icon-Moon:before {
  content: "\e926";
}
.icon-Partly-Cloudy:before {
  content: "\e927";
}
.icon-Rain:before {
  content: "\e928";
}
.icon-Shower:before {
  content: "\e929";
}
.icon-Snowflake:before {
  content: "\e92a";
}
.icon-Sun:before {
  content: "\e92b";
}
.icon-Thermometer:before {
  content: "\e92c";
}
.icon-Thunder:before {
  content: "\e92d";
}
.icon-Tornado:before {
  content: "\e92e";
}
.icon-Umbrella:before {
  content: "\e92f";
}
.icon-Water-Drop:before {
  content: "\e930";
}
.icon-Windsock:before {
  content: "\e931";
}
.icon-Kettle---Alt:before {
  content: "\e932";
}
.icon-Apple:before {
  content: "\e933";
}
.icon-Birthday-Cake:before {
  content: "\e934";
}
.icon-Bowl:before {
  content: "\e935";
}
.icon-Bread:before {
  content: "\e936";
}
.icon-Carrot:before {
  content: "\e937";
}
.icon-Cocktail:before {
  content: "\e938";
}
.icon-Coffee-Cup:before {
  content: "\e939";
}
.icon-Coffee:before {
  content: "\e93a";
}
.icon-Cooking-Pot:before {
  content: "\e93b";
}
.icon-Fork-And-Knife:before {
  content: "\e93c";
}
.icon-Grape:before {
  content: "\e93d";
}
.icon-Ice-Cream:before {
  content: "\e93e";
}
.icon-Kettle:before {
  content: "\e93f";
}
.icon-Kiwi:before {
  content: "\e940";
}
.icon-Lemon:before {
  content: "\e941";
}
.icon-Lid:before {
  content: "\e942";
}
.icon-Mug:before {
  content: "\e943";
}
.icon-Orange:before {
  content: "\e944";
}
.icon-Pizza-Slicer:before {
  content: "\e945";
}
.icon-Pizza:before {
  content: "\e946";
}
.icon-Plates:before {
  content: "\e947";
}
.icon-Salt--Pepper-Mill:before {
  content: "\e948";
}
.icon-Water:before {
  content: "\e949";
}
.icon-Watermelon:before {
  content: "\e94a";
}
.icon-Wine-Glass---Alt:before {
  content: "\e94b";
}
.icon-Wine-Glass:before {
  content: "\e94c";
}
.icon-Wine:before {
  content: "\e94d";
}
.icon-Chair:before {
  content: "\e94e";
}
.icon-Balloon:before {
  content: "\e94f";
}
.icon-Basketball:before {
  content: "\e950";
}
.icon-Bathtub:before {
  content: "\e951";
}
.icon-Beauty:before {
  content: "\e952";
}
.icon-Bed:before {
  content: "\e953";
}
.icon-Bird-House:before {
  content: "\e954";
}
.icon-Briefcase:before {
  content: "\e955";
}
.icon-Chair---Alt:before {
  content: "\e956";
}
.icon-Comb:before {
  content: "\e957";
}
.icon-Drawers:before {
  content: "\e958";
}
.icon-Dumbbell:before {
  content: "\e959";
}
.icon-Handbag:before {
  content: "\e95a";
}
.icon-Hanger:before {
  content: "\e95b";
}
.icon-Hook:before {
  content: "\e95c";
}
.icon-Planet:before {
  content: "\e95d";
}
.icon-Shovel:before {
  content: "\e95e";
}
.icon-Shower1:before {
  content: "\e95f";
}
.icon-Smoking:before {
  content: "\e960";
}
.icon-Soap-Dispenser:before {
  content: "\e961";
}
.icon-Sofa:before {
  content: "\e962";
}
.icon-Suitcase:before {
  content: "\e963";
}
.icon-Towel:before {
  content: "\e964";
}
.icon-Trees:before {
  content: "\e965";
}
.icon-Tshirt:before {
  content: "\e966";
}
.icon-Wedding-Ring:before {
  content: "\e967";
}
.icon-Weight:before {
  content: "\e968";
}
.icon-Whistle:before {
  content: "\e969";
}
.icon-Clock:before {
  content: "\e96a";
}
.icon-Alarm:before {
  content: "\e96b";
}
.icon-Bell:before {
  content: "\e96c";
}
.icon-Calendar---Add:before {
  content: "\e96d";
}
.icon-Calendar---Check:before {
  content: "\e96e";
}
.icon-Calendar---Delete:before {
  content: "\e96f";
}
.icon-Calendar---Remove:before {
  content: "\e970";
}
.icon-Calendar:before {
  content: "\e971";
}
.icon-Dashboard:before {
  content: "\e972";
}
.icon-Sandglass:before {
  content: "\e973";
}
.icon-Timer:before {
  content: "\e974";
}
.icon-Flow---Parallal:before {
  content: "\e975";
}
.icon-Add:before {
  content: "\e976";
}
.icon-Bluetooth:before {
  content: "\e977";
}
.icon-Check-All---Alt:before {
  content: "\e978";
}
.icon-Check-All:before {
  content: "\e979";
}
.icon-Cross:before {
  content: "\e97a";
}
.icon-Danger:before {
  content: "\e97b";
}
.icon-Do-Not-Enter:before {
  content: "\e97c";
}
.icon-Expand:before {
  content: "\e97d";
}
.icon-External-Link:before {
  content: "\e97e";
}
.icon-Fail:before {
  content: "\e97f";
}
.icon-Flow---Branch:before {
  content: "\e980";
}
.icon-Flow---Cascade:before {
  content: "\e981";
}
.icon-Flow---Line:before {
  content: "\e982";
}
.icon-Flow---Tree:before {
  content: "\e983";
}
.icon-Help:before {
  content: "\e984";
}
.icon-Infinite:before {
  content: "\e985";
}
.icon-Information:before {
  content: "\e986";
}
.icon-Keyboard:before {
  content: "\e987";
}
.icon-Lock-Rotation:before {
  content: "\e988";
}
.icon-Maximize---Alt:before {
  content: "\e989";
}
.icon-Maximize:before {
  content: "\e98a";
}
.icon-Merge:before {
  content: "\e98b";
}
.icon-Minimize---Alt:before {
  content: "\e98c";
}
.icon-Minimize:before {
  content: "\e98d";
}
.icon-Monitor:before {
  content: "\e98e";
}
.icon-No:before {
  content: "\e98f";
}
.icon-Notification:before {
  content: "\e990";
}
.icon-Phone-Rotate:before {
  content: "\e991";
}
.icon-Phone-Shake:before {
  content: "\e992";
}
.icon-Power:before {
  content: "\e993";
}
.icon-Recycle:before {
  content: "\e994";
}
.icon-Reload:before {
  content: "\e995";
}
.icon-Remove:before {
  content: "\e996";
}
.icon-Restore:before {
  content: "\e997";
}
.icon-Retweet:before {
  content: "\e998";
}
.icon-Sign-In:before {
  content: "\e999";
}
.icon-Sign-Out:before {
  content: "\e99a";
}
.icon-Signal---Alt:before {
  content: "\e99b";
}
.icon-Signal:before {
  content: "\e99c";
}
.icon-Sleep:before {
  content: "\e99d";
}
.icon-Sort:before {
  content: "\e99e";
}
.icon-Split:before {
  content: "\e99f";
}
.icon-Stop:before {
  content: "\e9a0";
}
.icon-Success:before {
  content: "\e9a1";
}
.icon-Swap:before {
  content: "\e9a2";
}
.icon-Switch:before {
  content: "\e9a3";
}
.icon-Sync:before {
  content: "\e9a4";
}
.icon-Tick:before {
  content: "\e9a5";
}
.icon-USB:before {
  content: "\e9a6";
}
.icon-Wifi:before {
  content: "\e9a7";
}
.icon-Align---Center:before {
  content: "\e9a8";
}
.icon-Align---Left:before {
  content: "\e9a9";
}
.icon-Align---Right:before {
  content: "\e9aa";
}
.icon-Anchor:before {
  content: "\e9ab";
}
.icon-Bold:before {
  content: "\e9ac";
}
.icon-Bullets:before {
  content: "\e9ad";
}
.icon-Close-Quote:before {
  content: "\e9ae";
}
.icon-Code:before {
  content: "\e9af";
}
.icon-Collapse:before {
  content: "\e9b0";
}
.icon-Columns:before {
  content: "\e9b1";
}
.icon-Copy:before {
  content: "\e9b2";
}
.icon-Decrease-Level:before {
  content: "\e9b3";
}
.icon-Expand1:before {
  content: "\e9b4";
}
.icon-Filter:before {
  content: "\e9b5";
}
.icon-Font-Size:before {
  content: "\e9b6";
}
.icon-Grid---Alt:before {
  content: "\e9b7";
}
.icon-Grid:before {
  content: "\e9b8";
}
.icon-Increase-Level:before {
  content: "\e9b9";
}
.icon-Italic:before {
  content: "\e9ba";
}
.icon-Justify:before {
  content: "\e9bb";
}
.icon-Layout:before {
  content: "\e9bc";
}
.icon-List---Alt:before {
  content: "\e9bd";
}
.icon-List:before {
  content: "\e9be";
}
.icon-Numbers:before {
  content: "\e9bf";
}
.icon-Open-Quote:before {
  content: "\e9c0";
}
.icon-Patse:before {
  content: "\e9c1";
}
.icon-Redo:before {
  content: "\e9c2";
}
.icon-Reference:before {
  content: "\e9c3";
}
.icon-Rename:before {
  content: "\e9c4";
}
.icon-Strikethrough:before {
  content: "\e9c5";
}
.icon-Type:before {
  content: "\e9c6";
}
.icon-Underline:before {
  content: "\e9c7";
}
.icon-Undo:before {
  content: "\e9c8";
}
.icon-Window:before {
  content: "\e9c9";
}
.icon-Windows:before {
  content: "\e9ca";
}
.icon-Box---Download:before {
  content: "\e9cb";
}
.icon-Box---Upload:before {
  content: "\e9cc";
}
.icon-Box:before {
  content: "\e9cd";
}
.icon-Cloud---Download:before {
  content: "\e9ce";
}
.icon-Cloud---Upload:before {
  content: "\e9cf";
}
.icon-Cloud1:before {
  content: "\e9d0";
}
.icon-Download---Alt:before {
  content: "\e9d1";
}
.icon-File---Add:before {
  content: "\e9d2";
}
.icon-File---Checked:before {
  content: "\e9d3";
}
.icon-File---Cloud:before {
  content: "\e9d4";
}
.icon-File---Delete:before {
  content: "\e9d5";
}
.icon-File---Disable:before {
  content: "\e9d6";
}
.icon-File---Download:before {
  content: "\e9d7";
}
.icon-File---Lock:before {
  content: "\e9d8";
}
.icon-File---Music:before {
  content: "\e9d9";
}
.icon-File---Photo:before {
  content: "\e9da";
}
.icon-File---Remove:before {
  content: "\e9db";
}
.icon-File---Search:before {
  content: "\e9dc";
}
.icon-File---Upload:before {
  content: "\e9dd";
}
.icon-File---Video:before {
  content: "\e9de";
}
.icon-File:before {
  content: "\e9df";
}
.icon-Files:before {
  content: "\e9e0";
}
.icon-Folder---Add:before {
  content: "\e9e1";
}
.icon-Folder---Checked:before {
  content: "\e9e2";
}
.icon-Folder---Cloud:before {
  content: "\e9e3";
}
.icon-Folder---Delete:before {
  content: "\e9e4";
}
.icon-Folder---Disable:before {
  content: "\e9e5";
}
.icon-Folder---Download:before {
  content: "\e9e6";
}
.icon-Folder---Lock:before {
  content: "\e9e7";
}
.icon-Folder---Music:before {
  content: "\e9e8";
}
.icon-Folder---Open:before {
  content: "\e9e9";
}
.icon-Folder---Photo:before {
  content: "\e9ea";
}
.icon-Folder---Remove:before {
  content: "\e9eb";
}
.icon-Folder---Search:before {
  content: "\e9ec";
}
.icon-Folder---Upload:before {
  content: "\e9ed";
}
.icon-Folder---Video:before {
  content: "\e9ee";
}
.icon-Folder:before {
  content: "\e9ef";
}
.icon-Save:before {
  content: "\e9f0";
}
.icon-Share---1:before {
  content: "\e9f1";
}
.icon-Share---2:before {
  content: "\e9f2";
}
.icon-Share---3:before {
  content: "\e9f3";
}
.icon-Spinner:before {
  content: "\e9f4";
}
.icon-Upload:before {
  content: "\e9f5";
}
.icon-Apple-Watch:before {
  content: "\e9f6";
}
.icon-Battery-Charging:before {
  content: "\e9f7";
}
.icon-Battery-Empty:before {
  content: "\e9f8";
}
.icon-Battery-Full:before {
  content: "\e9f9";
}
.icon-Battery-Low:before {
  content: "\e9fa";
}
.icon-Battery-Medium:before {
  content: "\e9fb";
}
.icon-Chip:before {
  content: "\e9fc";
}
.icon-Computer:before {
  content: "\e9fd";
}
.icon-Disk:before {
  content: "\e9fe";
}
.icon-Flashlight:before {
  content: "\e9ff";
}
.icon-Gamepad:before {
  content: "\ea00";
}
.icon-Hair-Dryer:before {
  content: "\ea01";
}
.icon-Lamp:before {
  content: "\ea02";
}
.icon-Laptop:before {
  content: "\ea03";
}
.icon-Lightbulb:before {
  content: "\ea04";
}
.icon-Magic-Mouse:before {
  content: "\ea05";
}
.icon-Microwave-Oven:before {
  content: "\ea06";
}
.icon-Mobile-Phone:before {
  content: "\ea07";
}
.icon-Mouse:before {
  content: "\ea08";
}
.icon-Plug:before {
  content: "\ea09";
}
.icon-Power-Socket:before {
  content: "\ea0a";
}
.icon-Printer:before {
  content: "\ea0b";
}
.icon-Radio:before {
  content: "\ea0c";
}
.icon-Refrigerator:before {
  content: "\ea0d";
}
.icon-Router:before {
  content: "\ea0e";
}
.icon-Security-Camera:before {
  content: "\ea0f";
}
.icon-Server:before {
  content: "\ea10";
}
.icon-Shredder:before {
  content: "\ea11";
}
.icon-Smart-Phone:before {
  content: "\ea12";
}
.icon-Speaker:before {
  content: "\ea13";
}
.icon-Tablet:before {
  content: "\ea14";
}
.icon-TV:before {
  content: "\ea15";
}
.icon-Video-Camera:before {
  content: "\ea16";
}
.icon-Washing-Machine:before {
  content: "\ea17";
}
.icon-Watch:before {
  content: "\ea18";
}
.icon-Book---Alt:before {
  content: "\ea19";
}
.icon-Book---Checked:before {
  content: "\ea1a";
}
.icon-Book---Download:before {
  content: "\ea1b";
}
.icon-Book---Open:before {
  content: "\ea1c";
}
.icon-Book---Search:before {
  content: "\ea1d";
}
.icon-Book:before {
  content: "\ea1e";
}
.icon-Glasses:before {
  content: "\ea1f";
}
.icon-Globe:before {
  content: "\ea20";
}
.icon-Mortar-Board:before {
  content: "\ea21";
}
.icon-News:before {
  content: "\ea22";
}
.icon-Notebook:before {
  content: "\ea23";
}
.icon-Podium:before {
  content: "\ea24";
}
.icon-Projector-Screen:before {
  content: "\ea25";
}
.icon-Technology:before {
  content: "\ea26";
}
.icon-Whiteboard:before {
  content: "\ea27";
}
.icon-Airplay:before {
  content: "\ea28";
}
.icon-Album:before {
  content: "\ea29";
}
.icon-Camera:before {
  content: "\ea2a";
}
.icon-Disc:before {
  content: "\ea2b";
}
.icon-Eject:before {
  content: "\ea2c";
}
.icon-Equalizer:before {
  content: "\ea2d";
}
.icon-Headphones:before {
  content: "\ea2e";
}
.icon-Image---Alt:before {
  content: "\ea2f";
}
.icon-Image:before {
  content: "\ea30";
}
.icon-Knob:before {
  content: "\ea31";
}
.icon-Mic:before {
  content: "\ea32";
}
.icon-Movie---Alt:before {
  content: "\ea33";
}
.icon-Movie:before {
  content: "\ea34";
}
.icon-Music-Note---Alt:before {
  content: "\ea35";
}
.icon-Music-Note:before {
  content: "\ea36";
}
.icon-Pause:before {
  content: "\ea37";
}
.icon-Play:before {
  content: "\ea38";
}
.icon-Playback-Fast-Forward:before {
  content: "\ea39";
}
.icon-Playback-Next:before {
  content: "\ea3a";
}
.icon-Playback-Previous:before {
  content: "\ea3b";
}
.icon-Playback-Rewind:before {
  content: "\ea3c";
}
.icon-Playlist:before {
  content: "\ea3d";
}
.icon-Podcast:before {
  content: "\ea3e";
}
.icon-Record:before {
  content: "\ea3f";
}
.icon-Reload1:before {
  content: "\ea40";
}
.icon-Repeat:before {
  content: "\ea41";
}
.icon-Shuffle:before {
  content: "\ea42";
}
.icon-Stop1:before {
  content: "\ea43";
}
.icon-Streaming-Music:before {
  content: "\ea44";
}
.icon-Video:before {
  content: "\ea45";
}
.icon-Voicemail:before {
  content: "\ea46";
}
.icon-Volume---Low:before {
  content: "\ea47";
}
.icon-Volume---Max:before {
  content: "\ea48";
}
.icon-Volume---Medium:before {
  content: "\ea49";
}
.icon-Volume---Mute:before {
  content: "\ea4a";
}
.icon-Building:before {
  content: "\ea4b";
}
.icon-Compass:before {
  content: "\ea4c";
}
.icon-Direction---Alt:before {
  content: "\ea4d";
}
.icon-Direction1:before {
  content: "\ea4e";
}
.icon-Factory:before {
  content: "\ea4f";
}
.icon-House:before {
  content: "\ea50";
}
.icon-Map:before {
  content: "\ea51";
}
.icon-Pin---1:before {
  content: "\ea52";
}
.icon-Pin---2:before {
  content: "\ea53";
}
.icon-Pin---3:before {
  content: "\ea54";
}
.icon-Street-Sign:before {
  content: "\ea55";
}
.icon-World:before {
  content: "\ea56";
}
.icon-Bank:before {
  content: "\ea57";
}
.icon-Barcode-Scan:before {
  content: "\ea58";
}
.icon-Calculator:before {
  content: "\ea59";
}
.icon-Credit-Card:before {
  content: "\ea5a";
}
.icon-Gift:before {
  content: "\ea5b";
}
.icon-Line-Chart:before {
  content: "\ea5c";
}
.icon-Money---Alt:before {
  content: "\ea5d";
}
.icon-Money:before {
  content: "\ea5e";
}
.icon-Pie-Chart---Alt:before {
  content: "\ea5f";
}
.icon-Pie-Chart:before {
  content: "\ea60";
}
.icon-Pillow-Chart---1:before {
  content: "\ea61";
}
.icon-Pillow-Chart---2:before {
  content: "\ea62";
}
.icon-Pillow-Chart---3:before {
  content: "\ea63";
}
.icon-Safe:before {
  content: "\ea64";
}
.icon-Shopping-Bag:before {
  content: "\ea65";
}
.icon-Shopping-Basket:before {
  content: "\ea66";
}
.icon-Shopping-Cart:before {
  content: "\ea67";
}
.icon-Store:before {
  content: "\ea68";
}
.icon-Wallet:before {
  content: "\ea69";
}
.icon-Hand---Alt:before {
  content: "\ea6a";
}
.icon-Hand---Pointing-Down:before {
  content: "\ea6b";
}
.icon-Hand---Pointing-Left:before {
  content: "\ea6c";
}
.icon-Hand---Pointing-Right:before {
  content: "\ea6d";
}
.icon-Hand---Pointing-Up:before {
  content: "\ea6e";
}
.icon-Hand:before {
  content: "\ea6f";
}
.icon-Add-Contacts:before {
  content: "\ea70";
}
.icon-Box---In:before {
  content: "\ea71";
}
.icon-Box---Out:before {
  content: "\ea72";
}
.icon-Box-Alt:before {
  content: "\ea73";
}
.icon-Box1:before {
  content: "\ea74";
}
.icon-Brain:before {
  content: "\ea75";
}
.icon-Chat:before {
  content: "\ea76";
}
.icon-Comment---Alt:before {
  content: "\ea77";
}
.icon-Comment:before {
  content: "\ea78";
}
.icon-Contacts:before {
  content: "\ea79";
}
.icon-Forward---Alt:before {
  content: "\ea7a";
}
.icon-Forward:before {
  content: "\ea7b";
}
.icon-Junk-Box:before {
  content: "\ea7c";
}
.icon-Keypad:before {
  content: "\ea7d";
}
.icon-Loudspeaker:before {
  content: "\ea7e";
}
.icon-Mail:before {
  content: "\ea7f";
}
.icon-Man:before {
  content: "\ea80";
}
.icon-Message---Smile:before {
  content: "\ea81";
}
.icon-Message:before {
  content: "\ea82";
}
.icon-Note:before {
  content: "\ea83";
}
.icon-People-Female:before {
  content: "\ea84";
}
.icon-People:before {
  content: "\ea85";
}
.icon-Phone:before {
  content: "\ea86";
}
.icon-Quill:before {
  content: "\ea87";
}
.icon-Reply---Alt:before {
  content: "\ea88";
}
.icon-Reply-All---Alt:before {
  content: "\ea89";
}
.icon-Reply-All:before {
  content: "\ea8a";
}
.icon-Reply:before {
  content: "\ea8b";
}
.icon-Send:before {
  content: "\ea8c";
}
.icon-User-Alt:before {
  content: "\ea8d";
}
.icon-User-Female:before {
  content: "\ea8e";
}
.icon-User:before {
  content: "\ea8f";
}
.icon-Users:before {
  content: "\ea90";
}
.icon-Woman:before {
  content: "\ea91";
}
.icon-Anchor1:before {
  content: "\ea92";
}
.icon-Clipboard:before {
  content: "\ea93";
}
.icon-Crop:before {
  content: "\ea94";
}
.icon-Envelope:before {
  content: "\ea95";
}
.icon-Eye-Dropper:before {
  content: "\ea96";
}
.icon-Layers:before {
  content: "\ea97";
}
.icon-Magic-Wand:before {
  content: "\ea98";
}
.icon-Paint-Brush:before {
  content: "\ea99";
}
.icon-Paint-Bucket:before {
  content: "\ea9a";
}
.icon-Paint-Roller:before {
  content: "\ea9b";
}
.icon-Pen:before {
  content: "\ea9c";
}
.icon-Pixel:before {
  content: "\ea9d";
}
.icon-Ruler:before {
  content: "\ea9e";
}
.icon-Scissors:before {
  content: "\ea9f";
}
.icon-Stacks:before {
  content: "\eaa0";
}
.icon-Attachment:before {
  content: "\eaa1";
}
.icon-Backspace:before {
  content: "\eaa2";
}
.icon-Binoculars:before {
  content: "\eaa3";
}
.icon-Bookmark:before {
  content: "\eaa4";
}
.icon-Compose:before {
  content: "\eaa5";
}
.icon-Crosshair:before {
  content: "\eaa6";
}
.icon-Crown:before {
  content: "\eaa7";
}
.icon-Cursor:before {
  content: "\eaa8";
}
.icon-Dislike:before {
  content: "\eaa9";
}
.icon-Emoji:before {
  content: "\eaaa";
}
.icon-Flag---Alt:before {
  content: "\eaab";
}
.icon-Flag:before {
  content: "\eaac";
}
.icon-Flash:before {
  content: "\eaad";
}
.icon-Guard:before {
  content: "\eaae";
}
.icon-Half-Star:before {
  content: "\eaaf";
}
.icon-Heart:before {
  content: "\eab0";
}
.icon-Key:before {
  content: "\eab1";
}
.icon-Like:before {
  content: "\eab2";
}
.icon-Link:before {
  content: "\eab3";
}
.icon-Lock:before {
  content: "\eab4";
}
.icon-Loupe:before {
  content: "\eab5";
}
.icon-More---Alt:before {
  content: "\eab6";
}
.icon-More:before {
  content: "\eab7";
}
.icon-Move:before {
  content: "\eab8";
}
.icon-Pencil:before {
  content: "\eab9";
}
.icon-Settings:before {
  content: "\eaba";
}
.icon-Shield:before {
  content: "\eabb";
}
.icon-Star:before {
  content: "\eabc";
}
.icon-Tag:before {
  content: "\eabd";
}
.icon-Target:before {
  content: "\eabe";
}
.icon-Ticket:before {
  content: "\eabf";
}
.icon-Trash:before {
  content: "\eac0";
}
.icon-Trophy:before {
  content: "\eac1";
}
.icon-Unlink:before {
  content: "\eac2";
}
.icon-Unlock:before {
  content: "\eac3";
}
.icon-View:before {
  content: "\eac4";
}
.icon-Warning:before {
  content: "\eac5";
}
.icon-Wranch:before {
  content: "\eac6";
}
.icon-Zoom-In:before {
  content: "\eac7";
}
.icon-Zoom-Out:before {
  content: "\eac8";
}
.icon-activity:before {
  content: "\eac9";
}
.icon-airplay:before {
  content: "\eaca";
}
.icon-alert-circle:before {
  content: "\eacb";
}
.icon-alert-octagon:before {
  content: "\eacc";
}
.icon-alert-triangle:before {
  content: "\eacd";
}
.icon-align-center:before {
  content: "\eace";
}
.icon-align-justify:before {
  content: "\eacf";
}
.icon-align-left:before {
  content: "\ead0";
}
.icon-align-right:before {
  content: "\ead1";
}
.icon-anchor:before {
  content: "\ead2";
}
.icon-aperture:before {
  content: "\ead3";
}
.icon-archive:before {
  content: "\ead4";
}
.icon-arrow-down-circle:before {
  content: "\ead5";
}
.icon-arrow-down-left:before {
  content: "\ead6";
}
.icon-arrow-down-right:before {
  content: "\ead7";
}
.icon-arrow-down:before {
  content: "\ead8";
}
.icon-arrow-left-circle:before {
  content: "\ead9";
}
.icon-arrow-left:before {
  content: "\eada";
}
.icon-arrow-right-circle:before {
  content: "\eadb";
}
.icon-arrow-right:before {
  content: "\eadc";
}
.icon-arrow-up-circle:before {
  content: "\eadd";
}
.icon-arrow-up-left:before {
  content: "\eade";
}
.icon-arrow-up-right:before {
  content: "\eadf";
}
.icon-arrow-up:before {
  content: "\eae0";
}
.icon-at-sign:before {
  content: "\eae1";
}
.icon-award:before {
  content: "\eae2";
}
.icon-bar-chart-2:before {
  content: "\eae3";
}
.icon-bar-chart:before {
  content: "\eae4";
}
.icon-battery-charging:before {
  content: "\eae5";
}
.icon-battery:before {
  content: "\eae6";
}
.icon-bell-off:before {
  content: "\eae7";
}
.icon-bell:before {
  content: "\eae8";
}
.icon-bluetooth:before {
  content: "\eae9";
}
.icon-bold:before {
  content: "\eaea";
}
.icon-book-open:before {
  content: "\eaeb";
}
.icon-book:before {
  content: "\eaec";
}
.icon-bookmark:before {
  content: "\eaed";
}
.icon-box:before {
  content: "\eaee";
}
.icon-briefcase:before {
  content: "\eaef";
}
.icon-calendar:before {
  content: "\eaf0";
}
.icon-camera-off:before {
  content: "\eaf1";
}
.icon-camera:before {
  content: "\eaf2";
}
.icon-cast:before {
  content: "\eaf3";
}
.icon-check-circle:before {
  content: "\eaf4";
}
.icon-check-square:before {
  content: "\eaf5";
}
.icon-check:before {
  content: "\eaf6";
}
.icon-chevron-down:before {
  content: "\eaf7";
}
.icon-chevron-left:before {
  content: "\eaf8";
}
.icon-chevron-right:before {
  content: "\eaf9";
}
.icon-chevron-up:before {
  content: "\eafa";
}
.icon-chevrons-down:before {
  content: "\eafb";
}
.icon-chevrons-left:before {
  content: "\eafc";
}
.icon-chevrons-right:before {
  content: "\eafd";
}
.icon-chevrons-up:before {
  content: "\eafe";
}
.icon-chrome:before {
  content: "\eaff";
}
.icon-circle:before {
  content: "\eb00";
}
.icon-clipboard:before {
  content: "\eb01";
}
.icon-clock:before {
  content: "\eb02";
}
.icon-cloud-drizzle:before {
  content: "\eb03";
}
.icon-cloud-lightning:before {
  content: "\eb04";
}
.icon-cloud-off:before {
  content: "\eb05";
}
.icon-cloud-rain:before {
  content: "\eb06";
}
.icon-cloud-snow:before {
  content: "\eb07";
}
.icon-cloud:before {
  content: "\eb08";
}
.icon-code:before {
  content: "\eb09";
}
.icon-codepen:before {
  content: "\eb0a";
}
.icon-coffee:before {
  content: "\eb0b";
}
.icon-command:before {
  content: "\eb0c";
}
.icon-compass:before {
  content: "\eb0d";
}
.icon-copy:before {
  content: "\eb0e";
}
.icon-corner-down-left:before {
  content: "\eb0f";
}
.icon-corner-down-right:before {
  content: "\eb10";
}
.icon-corner-left-down:before {
  content: "\eb11";
}
.icon-corner-left-up:before {
  content: "\eb12";
}
.icon-corner-right-down:before {
  content: "\eb13";
}
.icon-corner-right-up:before {
  content: "\eb14";
}
.icon-corner-up-left:before {
  content: "\eb15";
}
.icon-corner-up-right:before {
  content: "\eb16";
}
.icon-cpu:before {
  content: "\eb17";
}
.icon-credit-card:before {
  content: "\eb18";
}
.icon-crop:before {
  content: "\eb19";
}
.icon-crosshair:before {
  content: "\eb1a";
}
.icon-database:before {
  content: "\eb1b";
}
.icon-delete:before {
  content: "\eb1c";
}
.icon-disc:before {
  content: "\eb1d";
}
.icon-dollar-sign:before {
  content: "\eb1e";
}
.icon-download-cloud:before {
  content: "\eb1f";
}
.icon-download:before {
  content: "\eb20";
}
.icon-droplet:before {
  content: "\eb21";
}
.icon-edit-2:before {
  content: "\eb22";
}
.icon-edit-3:before {
  content: "\eb23";
}
.icon-edit:before {
  content: "\eb24";
}
.icon-external-link:before {
  content: "\eb25";
}
.icon-eye-off:before {
  content: "\eb26";
}
.icon-eye:before {
  content: "\eb27";
}
.icon-facebook:before {
  content: "\eb28";
}
.icon-fast-forward:before {
  content: "\eb29";
}
.icon-feather:before {
  content: "\eb2a";
}
.icon-figma:before {
  content: "\eb2b";
}
.icon-file-minus:before {
  content: "\eb2c";
}
.icon-file-plus:before {
  content: "\eb2d";
}
.icon-file-text:before {
  content: "\eb2e";
}
.icon-file:before {
  content: "\eb2f";
}
.icon-film:before {
  content: "\eb30";
}
.icon-filter:before {
  content: "\eb31";
}
.icon-flag:before {
  content: "\eb32";
}
.icon-folder-minus:before {
  content: "\eb33";
}
.icon-folder-plus:before {
  content: "\eb34";
}
.icon-folder:before {
  content: "\eb35";
}
.icon-frown:before {
  content: "\eb36";
}
.icon-gift:before {
  content: "\eb37";
}
.icon-git-branch:before {
  content: "\eb38";
}
.icon-git-commit:before {
  content: "\eb39";
}
.icon-git-merge:before {
  content: "\eb3a";
}
.icon-git-pull-request:before {
  content: "\eb3b";
}
.icon-github:before {
  content: "\eb3c";
}
.icon-gitlab:before {
  content: "\eb3d";
}
.icon-globe:before {
  content: "\eb3e";
}
.icon-grid:before {
  content: "\eb3f";
}
.icon-hard-drive:before {
  content: "\eb40";
}
.icon-hash:before {
  content: "\eb41";
}
.icon-headphones:before {
  content: "\eb42";
}
.icon-heart:before {
  content: "\eb43";
}
.icon-help-circle:before {
  content: "\eb44";
}
.icon-home:before {
  content: "\eb45";
}
.icon-image:before {
  content: "\eb46";
}
.icon-inbox:before {
  content: "\eb47";
}
.icon-info:before {
  content: "\eb48";
}
.icon-instagram:before {
  content: "\eb49";
}
.icon-italic:before {
  content: "\eb4a";
}
.icon-key:before {
  content: "\eb4b";
}
.icon-layers:before {
  content: "\eb4c";
}
.icon-layout:before {
  content: "\eb4d";
}
.icon-life-buoy:before {
  content: "\eb4e";
}
.icon-link-2:before {
  content: "\eb4f";
}
.icon-link:before {
  content: "\eb50";
}
.icon-linkedin:before {
  content: "\eb51";
}
.icon-list:before {
  content: "\eb52";
}
.icon-loader:before {
  content: "\eb53";
}
.icon-lock:before {
  content: "\eb54";
}
.icon-log-in:before {
  content: "\eb55";
}
.icon-log-out:before {
  content: "\eb56";
}
.icon-mail:before {
  content: "\eb57";
}
.icon-map-pin:before {
  content: "\eb58";
}
.icon-map:before {
  content: "\eb59";
}
.icon-maximize-2:before {
  content: "\eb5a";
}
.icon-maximize:before {
  content: "\eb5b";
}
.icon-meh:before {
  content: "\eb5c";
}
.icon-menu:before {
  content: "\eb5d";
}
.icon-message-circle:before {
  content: "\eb5e";
}
.icon-message-square:before {
  content: "\eb5f";
}
.icon-mic-off:before {
  content: "\eb60";
}
.icon-mic:before {
  content: "\eb61";
}
.icon-minimize-2:before {
  content: "\eb62";
}
.icon-minimize:before {
  content: "\eb63";
}
.icon-minus-circle:before {
  content: "\eb64";
}
.icon-minus-square:before {
  content: "\eb65";
}
.icon-minus:before {
  content: "\eb66";
}
.icon-monitor:before {
  content: "\eb67";
}
.icon-moon:before {
  content: "\eb68";
}
.icon-more-horizontal:before {
  content: "\eb69";
}
.icon-more-vertical:before {
  content: "\eb6a";
}
.icon-mouse-pointer:before {
  content: "\eb6b";
}
.icon-move:before {
  content: "\eb6c";
}
.icon-music:before {
  content: "\eb6d";
}
.icon-navigation-2:before {
  content: "\eb6e";
}
.icon-navigation:before {
  content: "\eb6f";
}
.icon-octagon:before {
  content: "\eb70";
}
.icon-package:before {
  content: "\eb71";
}
.icon-paperclip:before {
  content: "\eb72";
}
.icon-pause-circle:before {
  content: "\eb73";
}
.icon-pause:before {
  content: "\eb74";
}
.icon-pen-tool:before {
  content: "\eb75";
}
.icon-percent:before {
  content: "\eb76";
}
.icon-phone-call:before {
  content: "\eb77";
}
.icon-phone-forwarded:before {
  content: "\eb78";
}
.icon-phone-incoming:before {
  content: "\eb79";
}
.icon-phone-missed:before {
  content: "\eb7a";
}
.icon-phone-off:before {
  content: "\eb7b";
}
.icon-phone-outgoing:before {
  content: "\eb7c";
}
.icon-phone:before {
  content: "\eb7d";
}
.icon-pie-chart:before {
  content: "\eb7e";
}
.icon-play-circle:before {
  content: "\eb7f";
}
.icon-play:before {
  content: "\eb80";
}
.icon-plus-circle:before {
  content: "\eb81";
}
.icon-plus-square:before {
  content: "\eb82";
}
.icon-plus:before {
  content: "\eb83";
}
.icon-pocket:before {
  content: "\eb84";
}
.icon-power:before {
  content: "\eb85";
}
.icon-printer:before {
  content: "\eb86";
}
.icon-radio:before {
  content: "\eb87";
}
.icon-refresh-ccw:before {
  content: "\eb88";
}
.icon-refresh-cw:before {
  content: "\eb89";
}
.icon-repeat:before {
  content: "\eb8a";
}
.icon-rewind:before {
  content: "\eb8b";
}
.icon-rotate-ccw:before {
  content: "\eb8c";
}
.icon-rotate-cw:before {
  content: "\eb8d";
}
.icon-rss:before {
  content: "\eb8e";
}
.icon-save:before {
  content: "\eb8f";
}
.icon-scissors:before {
  content: "\eb90";
}
.icon-search:before {
  content: "\eb91";
}
.icon-send:before {
  content: "\eb92";
}
.icon-server:before {
  content: "\eb93";
}
.icon-settings:before {
  content: "\eb94";
}
.icon-share-2:before {
  content: "\eb95";
}
.icon-share:before {
  content: "\eb96";
}
.icon-shield-off:before {
  content: "\eb97";
}
.icon-shield:before {
  content: "\eb98";
}
.icon-shopping-bag:before {
  content: "\eb99";
}
.icon-shopping-cart:before {
  content: "\eb9a";
}
.icon-shuffle:before {
  content: "\eb9b";
}
.icon-sidebar:before {
  content: "\eb9c";
}
.icon-skip-back:before {
  content: "\eb9d";
}
.icon-skip-forward:before {
  content: "\eb9e";
}
.icon-slack:before {
  content: "\eb9f";
}
.icon-slash:before {
  content: "\eba0";
}
.icon-sliders:before {
  content: "\eba1";
}
.icon-smartphone:before {
  content: "\eba2";
}
.icon-smile:before {
  content: "\eba3";
}
.icon-speaker:before {
  content: "\eba4";
}
.icon-square:before {
  content: "\eba5";
}
.icon-star:before {
  content: "\eba6";
}
.icon-stop-circle:before {
  content: "\eba7";
}
.icon-sun:before {
  content: "\eba8";
}
.icon-sunrise:before {
  content: "\eba9";
}
.icon-sunset:before {
  content: "\ebaa";
}
.icon-tablet:before {
  content: "\ebab";
}
.icon-tag:before {
  content: "\ebac";
}
.icon-target:before {
  content: "\ebad";
}
.icon-terminal:before {
  content: "\ebae";
}
.icon-thermometer:before {
  content: "\ebaf";
}
.icon-thumbs-down:before {
  content: "\ebb0";
}
.icon-thumbs-up:before {
  content: "\ebb1";
}
.icon-toggle-left:before {
  content: "\ebb2";
}
.icon-toggle-right:before {
  content: "\ebb3";
}
.icon-trash-2:before {
  content: "\ebb4";
}
.icon-trash:before {
  content: "\ebb5";
}
.icon-trello:before {
  content: "\ebb6";
}
.icon-trending-down:before {
  content: "\ebb7";
}
.icon-trending-up:before {
  content: "\ebb8";
}
.icon-triangle:before {
  content: "\ebb9";
}
.icon-truck:before {
  content: "\ebba";
}
.icon-tv:before {
  content: "\ebbb";
}
.icon-twitter:before {
  content: "\ebbc";
}
.icon-type:before {
  content: "\ebbd";
}
.icon-umbrella:before {
  content: "\ebbe";
}
.icon-underline:before {
  content: "\ebbf";
}
.icon-unlock:before {
  content: "\ebc0";
}
.icon-upload-cloud:before {
  content: "\ebc1";
}
.icon-upload:before {
  content: "\ebc2";
}
.icon-user-check:before {
  content: "\ebc3";
}
.icon-user-minus:before {
  content: "\ebc4";
}
.icon-user-plus:before {
  content: "\ebc5";
}
.icon-user-x:before {
  content: "\ebc6";
}
.icon-user:before {
  content: "\ebc7";
}
.icon-users:before {
  content: "\ebc8";
}
.icon-video-off:before {
  content: "\ebc9";
}
.icon-video:before {
  content: "\ebca";
}
.icon-voicemail:before {
  content: "\ebcb";
}
.icon-volume-1:before {
  content: "\ebcc";
}
.icon-volume-2:before {
  content: "\ebcd";
}
.icon-volume-x:before {
  content: "\ebce";
}
.icon-volume:before {
  content: "\ebcf";
}
.icon-watch:before {
  content: "\ebd0";
}
.icon-wifi-off:before {
  content: "\ebd1";
}
.icon-wifi:before {
  content: "\ebd2";
}
.icon-wind:before {
  content: "\ebd3";
}
.icon-x-circle:before {
  content: "\ebd4";
}
.icon-x-octagon:before {
  content: "\ebd5";
}
.icon-x-square:before {
  content: "\ebd6";
}
.icon-x:before {
  content: "\ebd7";
}
.icon-youtube:before {
  content: "\ebd8";
}
.icon-zap-off:before {
  content: "\ebd9";
}
.icon-zap:before {
  content: "\ebda";
}
.icon-zoom-in:before {
  content: "\ebdb";
}
.icon-zoom-out:before {
  content: "\ebdc";
}
.icon-facebook-o:before {
  content: "\ebe0";
}
.icon-twitter-o:before {
  content: "\ebe1";
}
.icon-youtube-o:before {
  content: "\ebe2";
}
.icon-slack-o:before {
  content: "\ebe3";
}
.icon-linkedin-o:before {
  content: "\ebe4";
}
.icon-ui-heart:before {
  content: "\ebdd";
}
.icon-ui-heart-fill:before {
  content: "\ebde";
}
.icon-ui-star:before {
  content: "\ebdf";
}
