//
// pagination
//
//

.pagination {
  .page-link {
    padding: 0;
    text-align: center;
    width: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
    height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
    line-height: calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
  }

  &[class*="-lg"] {
    .page-link {
      width: calc(#{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2});
      height: calc(#{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2});
      line-height: calc(#{$input-line-height-lg * 1em} + #{$input-padding-y-lg * 2});
    }
  }

  &[class*="-sm"] {
    .page-link {
      width: calc(#{$input-line-height-sm * 1em} + #{$input-padding-y-sm * 2});
      height: calc(#{$input-line-height-sm * 1em} + #{$input-padding-y-sm * 2});
      line-height: calc(#{$input-line-height-sm * 1em} + #{$input-padding-y-sm * 2});
    }
  }
}